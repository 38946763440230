
import { defineComponent, onBeforeUnmount, reactive, ref } from "vue";
import type { FormInstance, Rule } from "ant-design-vue/es/form";
import videoJs from "@/assets/agora-videojs/index.js";
import canPlayType from "@/utils/canPlayType";

export default defineComponent({
  setup() {
    const typeList = [
      { value: "video/hls", label: "hls" },
      { value: "video/flv", label: "flv" },
      { value: "video/mp4", label: "mp4" },
    ];
    const formRef = ref<FormInstance>();
    const videoWrapperRef = ref<HTMLVideoElement | null>(null);
    const formState = reactive({
      playUrl: "",
      videoType: typeList[0].value,
      reportData: false,
      token: "32f24ab2ddb74f508aa9286c356cec84",
      vid: "",
      lowDelay: false,
      hasVideo: true,
      isLive: true,
      debug: true,
    });
    const formRules: Record<string, Rule[]> = {
      playUrl: [
        { required: true, trigger: "change", message: "请输入拉流地址" },
      ],
      videoType: [
        { required: true, trigger: "change", message: "请选择播放类型" },
      ],
      token: [{ required: true, trigger: "change", message: "请输入token" }],
      vid: [
        {
          required: true,
          validator(_rule: Rule, value: string) {
            if (!value) {
              return Promise.reject("请输入vid");
            }
            const reg = /^[1-9][0-9]*$/;
            if (value.match(reg)) {
              return Promise.resolve();
            }
            return Promise.reject("非法数字");
          },
          trigger: "change",
        },
      ],
    };

    const createVideoElement = () => {
      if (!videoWrapperRef.value) {
        return;
      }
      videoWrapperRef.value.innerHTML = "";
      const videoElement = document.createElement("video") as HTMLVideoElement;
      videoElement.className = "video-js tech-player";
      videoElement.id = "video-player";
      videoWrapperRef.value.appendChild(videoElement);
    };

    const checkForm = () => {
      if (formRef.value) {
        formRef.value
          .validateFields()
          .then(() => {
            initPlayer();
          })
          .catch(() => {
            // todo
          });
      }
    };
    let player: any = null;
    const initPlayer = () => {
      const options: any = {
        controls: true,
        autoplay: true,
        sources: [
          {
            src: formState.playUrl,
            type: formState.videoType,
          },
        ],
        plugins: {},
        html5: {
          flv: {
            lowDelay: formState.lowDelay,
            mediaDataSource: {
              hasVideo: formState.hasVideo,
              isLive: formState.isLive,
            },
          },
          hls: {
            config: {
              debug: true,
            },
          },
        },
      };
      if (formState.reportData) {
        // 数据上报
        options.plugins.DataReport = {
          token: formState.token,
          vid: Number(formState.vid),
          debug: formState.debug,
        };
      }
      const vj = videoJs as any;
      console.log(
        "是否支持hls",
        vj.Hls.isSupported() || canPlayType("application/vnd.apple.mpegurl")
      );
      console.log("是否支持flv", vj.flvJs.isSupported());
      console.log("options参数", options);
      destroyPlayer();
      createVideoElement();
      player = videoJs("video-player", options);
      if (formState.videoType === "video/hls") {
        const HlsEvents = videoJs.HlsEvents;

        Object.values(HlsEvents).forEach((eventName) => {
          player.on(eventName, ({ data }: any) => {
            console.log(eventName, data);
          });
        });
      }
    };
    const destroyPlayer = () => {
      if (player) {
        player?.dispose();
        player = null;
      }
    };
    const resetForm = () => {
      destroyPlayer();
      if (formRef.value) {
        formRef.value.resetFields();
      }
    };
    onBeforeUnmount(destroyPlayer);
    return {
      typeList,
      formState,
      formRules,
      formRef,
      checkForm,
      resetForm,
      videoWrapperRef,
    };
  },
});

<template>
  <div class="pc-test center-block width-1200">
    <Player />
  </div>
</template>


<style lang="scss">
.pc-test {
  margin-top: 50px !important;
  .video-wrapper{
    width: 1200px;
    height: 600px;
  }
  .form-wrapper{
    margin-top: 30px;
  }
}
</style>

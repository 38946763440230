import { Input, Select, Button, Form,Radio } from "ant-design-vue";
import { App } from "vue";

const components = [Input, Select, Select.Option, Button, Form,Radio];

const install = (app: App) => {
  components.forEach((component) => {
    app.use(component);
  });
  app.component('a-form-item',Form.FormItem)
  app.component('a-radio-group',Radio.Group)
};

export default {
  install,
};

<template>
  <div class="mobile-test">
    <Player />
  </div>
</template>




<style lang="scss">
.mobile-test {
  width: 100%;
  overflow-y: auto;
touch-action: pan-y;
-webkit-overflow-scrolling: touch;
  .video-wrapper{
    width: 100%;
    height: 30vh;
  }
  .form-wrapper{
      box-sizing: border-box;
      padding: 10px;
  }
}
</style>
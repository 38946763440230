import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Player from './components/player.vue'

import AntdVue from './antd-vue'

import '@/assets/agora-videojs/style.css'
import '@/assets/styles/index.scss'
import { isMobile } from './utils/is'

if(isMobile()){
    import('vconsole').then(res=>{
        const VConsole  = res.default
        if(VConsole){
            new VConsole();
        }
    })
}


const app = createApp(App)

app.component('Player',Player)

app.use(store).use(router).use(AntdVue).mount('#app')
